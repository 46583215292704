/* You can add global styles to this file, and also import other style files */
@import "../node_modules/hagebau-coremedia/src/lib/styles/_hagebau";
.alert-container {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
